import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`craigs`]}
        title="Home"
      />

      <section className="text-center">
        <div className="cloud">
          <h2 className="bg-transparent-white text-2xl font-bold inline-block py-5 px-6">
            The Craigs.xyz
          </h2>
        </div>

      </section>
    </Layout>
  );
}

export default IndexPage;
